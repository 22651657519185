import { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Brands } from "../../pages/brands/BrandsPage";

const styles: React.CSSProperties = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
};

const buttonStyle: React.CSSProperties = {
  marginTop: "15px",
  maxWidth: "140px",
};

const selltype = ["հատ", "Տուփ"];

export default function EditProduct(): JSX.Element {
  const [inputValues, setInputValues] = useState({
    name: "",
    name_ru: "",
    name_en: "",
    outer_carton: "",
    inner_carton: "",
    sale: "",
    price: "",
    artikul: "",
    code: "",
    brand_id: "",
    product_images: [], // Initialize images array
  });
  const [brandData, setBrandData] = useState<Brands[]>();
  const [btn, setBtn] = useState<boolean>(true);
  const { id } = useParams();
  const navigate = useNavigate();
  const URL = process.env.REACT_APP_BASE_URL;
  const [resetRemoveButton, setResetRemoveButton] = useState<any>(null)

  useEffect(() => {
    fetch(`${URL}/brands`)
      .then((req) => req.json())
      .then((res) => {
        setBrandData(res);
      });
  }, []);

  useEffect(() => {
    setResetRemoveButton(inputValues.product_images.length)
    console.log(resetRemoveButton, "--> inputValues.product_images.length")
  }, [inputValues.product_images])

  useEffect(() => {
    fetch(`${URL}/products/${id}`)
      .then((res) => res.json())
      .then((res) => {
        setInputValues({
          name: res.name,
          name_ru: res.name_ru,
          name_en: res.name_en,
          outer_carton: res.outer_carton,
          inner_carton: res.inner_carton,
          sale: res.sale,
          price: res.price,
          artikul: res.artikul,
          code: res.code,
          brand_id: res.brand_id,
          product_images: res.images,
        });
      })
      .catch((err) => console.error(err));
  }, [id]);

  function handleChange(e) {
    setBtn(false);
    const { value, name } = e.target;
    if (e.target?.files) {
      setInputValues((prev: any) => ({
        ...prev,
        product_images: e.target.files[0],
      }));
    } else {
      setInputValues((prev: any) => ({
        ...prev,
        [name]: value,
      }));
    }
  }

  function handleImageRemove(imageUrl: string) {
    setBtn(false)

    setInputValues((prev: any) => ({
      ...prev,
      product_images: prev.product_images.filter((image: any) => image.image_url !== imageUrl),
    }));
  }

  function handleSetMainImage(imageUrl: string) {
    setBtn(false)

    setInputValues((prev: any) => {
      const updatedImages = prev.product_images.map((image: any) => {
        // Update the existing is_main field
        if (image.image_url === imageUrl) {
          return { ...image, is_main: true }; // Set this image as main
        }
        return { ...image, is_main: false }; // Set other images as not main
      });
      return { ...prev, product_images: updatedImages };
    });
  }

  function handleAddImage(e: React.ChangeEvent<HTMLInputElement>) {
    setBtn(false);
    const addedFiles = e.target.files;
  
    if (addedFiles) {
      const newFiles = Array.from(addedFiles); // Convert FileList to an array
      setInputValues((prev: any) => ({
        ...prev,
        product_images: [...prev.product_images, ...newFiles], // Add new files directly
      }));
    }
  }
  
  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
  
    const formData = new FormData();
    formData.append("name", inputValues.name);
    formData.append("name_ru", inputValues.name_ru);
    formData.append("name_en", inputValues.name_en);
    formData.append("outer_carton", inputValues.outer_carton);
    formData.append("inner_carton", inputValues.inner_carton);
    formData.append("sale", inputValues.sale);
    formData.append("price", inputValues.price);
    formData.append("artikul", inputValues.artikul);
    formData.append("code", inputValues.code);
    formData.append("brand_id", inputValues.brand_id);
  
    inputValues.product_images.forEach((image: any, index:number) => {
      if (image instanceof File) {
        // Append new files
        formData.append("product_images", image);
      } else if (image.image_url) {
    
        formData.append("product_images", image.image_url);
        formData.append(`is_main${index}`, image.is_main ? "true" : "false");
      }
    });
  
    try {
      const response = await fetch(`${URL}/products/${id}`, {
        method: "PUT",
        body: formData,
      });
  
      if (response.ok) {
        navigate("/products");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }
  
console.log("Inputvalues length", inputValues.product_images.length)
  return (
    <div>
      <div style={styles}>
        <h2>Edit Product</h2>
        <button type="button" className="btn btn-link">
          <Link to="/products">Go back</Link>
        </button>
      </div>
      <form
        onSubmit={handleSubmit}
        style={{ display: "flex", flexDirection: "column", gap: "15px" }}
        method="put"
        encType="multipart/form-data"
      >
        <div className="form-group">
          <label>Product Name</label>
          <input
            onChange={handleChange}
            value={inputValues.name}
            name="name"
            type="text"
            className="form-control"
            placeholder="Product Name"
          />
        </div>

        <div className="form-group">
          <label>Ru Name</label>
          <input
            onChange={handleChange}
            value={inputValues.name_ru}
            name="name_ru"
            type="text"
            className="form-control"
            placeholder="Product Ru Name"
          />
        </div>

        <div className="form-group">
          <label>En Name</label>
          <input
            onChange={handleChange}
            value={inputValues.name_en}
            name="name_en"
            type="text"
            className="form-control"
            placeholder="Product En Name"
          />
        </div>

        <div className="form-group">
          <label>Outer Carton*</label>
          <input
            required
            name="outer_carton"
            value={inputValues.outer_carton}
            onChange={handleChange}
            type="text"
            className="form-control"
            placeholder="Correct count in outer Carton"
          />
        </div>

        <div className="form-group">
          <label>Inner Carton*</label>
          <input
            required
            name="inner_carton"
            value={inputValues.inner_carton}
            onChange={handleChange}
            type="text"
            className="form-control"
            placeholder="Correct count in inner Carton"
          />
        </div>

        <div className="form-group">
          <label>Sale type*</label>
          <select
            name="sale"
            className="form-control"
            value={inputValues.sale}
            onChange={handleChange}
          >
            <option value="Sale type" disabled>
              Sale Type
            </option>
            {selltype?.map((item, idx) => {
              return <option value={item} key={idx}>{item}</option>;
            })}
          </select>
        </div>

        <div className="form-group">
          <label>Product Price</label>
          <input
            onChange={handleChange}
            value={inputValues.price}
            name="price"
            type="text"
            className="form-control"
            placeholder="Product Price"
          />
        </div>

        <div className="form-group">
          <label>Product Artikul</label>
          <input
            onChange={handleChange}
            value={inputValues.artikul}
            name="artikul"
            type="text"
            className="form-control"
            placeholder="Product Artikul"
          />
        </div>

        <div className="form-group">
          <label>Product Code</label>
          <input
            onChange={handleChange}
            value={inputValues.code}
            name="code"
            type="text"
            className="form-control"
            placeholder="Product Code"
          />
        </div>

        <div className="form-group">
          <label>Select Brand*</label>
          <select
            name="brand_id"
            className="form-control"
            value={inputValues.brand_id}
            onChange={handleChange}
          >
            <option value="" disabled>
              Select Brand
            </option>
            {brandData?.map((brand) => {
              return (
                <option value={brand.id} key={brand.id}>
                  {brand.name}
                </option>
              );
            })}
          </select>
        </div>

        <div className="form-control">
          <label>Images*</label>
          <div className="edit_product_image_box">
            {inputValues.product_images.map((image: any) => {
              
              return (
                <div key={image.id} style={{ position: "relative" }}>
                <img
                  src={image?.image_url}
                  alt="product"
                  style={{
                    width: "70px",
                    height: "70px",
                    objectFit: "cover",
                  }}
                />
                  {!(resetRemoveButton <= 1) && (
                    <button
                    type="button"
                    style={{
                      position: "absolute",
                      top: "5px",
                      right: "5px",
                      backgroundColor: "transparent",
                      border: "none",
                      color: "red",
                      fontSize: "20px",
                      cursor: "pointer",
                    }}
                    onClick={() => handleImageRemove(image.image_url)}
                  >
                    X
                  </button>
                  )}
                <button
                  type="button"
                  style={{
                    backgroundColor: "transparent",
                    border: "none",
                    color: "green",
                    fontSize: "20px",
                    cursor: "pointer",
                  }}
                  onClick={() => handleSetMainImage(image.image_url)}
                >
                  {image.is_main === true ? "Main Image" : "Set as Main"}
                </button>
              </div>
              )
            })}

            <input type="file" onChange={handleAddImage} multiple  />
          </div>
        </div>

        

        <div style={buttonStyle}>
          <button
            className="btn btn-primary"
            type="submit"
            disabled={btn}
            style={{ width: "100%" }}
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
}
